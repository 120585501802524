var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title", { attrs: { "show-back-btn": "" } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.detail,
              expression: "loading.detail"
            }
          ],
          staticClass: "partition-area"
        },
        [
          _c(
            "el-form",
            {
              ref: "appForm",
              attrs: {
                model: _vm.appForm,
                rules: _vm.rules,
                "label-width": "180px",
                "label-suffix": _vm.constants.labelSuffix
              }
            },
            [
              _c(
                "ics-page-inner",
                { attrs: { title: "授信进度" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-steps",
                        {
                          attrs: {
                            active: _vm.stepActiveIndex,
                            "finish-status": "success"
                          }
                        },
                        [
                          _c("el-step", { attrs: { title: "申请" } }),
                          _c("el-step", { attrs: { title: "补件" } }),
                          _c("el-step", { attrs: { title: "资方审批" } }),
                          _c("el-step", { attrs: { title: "资方放款" } }),
                          _c("el-step", { attrs: { title: "还款" } }),
                          _c("el-step", { attrs: { title: "完成" } })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "ics-page-inner",
                { attrs: { title: "用信信息" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "用信单号" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(_vm.useDetail.useCode)
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "申请方" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.useDetail.customerName
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "申请金额(元)" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.moneyFormat(
                                _vm.useDetail.applyAmount,
                                2
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "ics-page-inner",
                { attrs: { title: "应收账款信息" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "凭证编号" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.payableInfoVo.sfNo
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("el-col"),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "应收金额" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.moneyFormat(_vm.payableInfoVo.amount, 2)
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "应收期限" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.dateFormat(
                                _vm.payableInfoVo.paymentTerm
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  !_vm._.isEmpty(_vm.payableInfoVo.filesList)
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "凭证文件" } },
                            _vm._l(_vm.payableInfoVo.filesList, function(
                              item,
                              index
                            ) {
                              return _c("p", { key: index }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-btn",
                                    attrs: { href: "javascript:" },
                                    on: {
                                      click: function($event) {
                                        return _vm.utils.downloadP(
                                          "other",
                                          item.url
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              ])
                            }),
                            0
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._.isEmpty(_vm.payableInfoVo.filesList)
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "凭证文件" } }, [
                            _c("p", [_vm._v("-")])
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "ics-page-inner",
                { attrs: { title: "发票信息" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "发票信息" } }, [
                        _c(
                          "a",
                          {
                            staticClass: "text-btn",
                            attrs: { href: "javascript:" },
                            on: {
                              click: function($event) {
                                return _vm.clickDialog("invoices")
                              }
                            }
                          },
                          [_vm._v("点击查看")]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "ics-page-inner",
                { attrs: { title: "付款计划信息" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "付款计划信息" } },
                        _vm._l(_vm.voucherFilesList, function(item, index) {
                          return _c("p", { key: index }, [
                            _c(
                              "a",
                              {
                                staticClass: "text-btn",
                                attrs: { href: "javascript:" },
                                on: {
                                  click: function($event) {
                                    return _vm.utils.downloadP("use", item.url)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          ])
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "ics-page-inner",
                { attrs: { title: "授信信息" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "关联授信" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.creditDetail.credit_code
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("el-col"),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "资方" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.creditDetail.cap_name
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "授信期限" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.creditDetail.effective_start === undefined
                                ? ""
                                : _vm.creditDetail.effective_start +
                                    " 至 " +
                                    _vm.creditDetail.effective_end
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "总授信额度(元)" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.moneyFormat(
                                  _vm.creditDetail.pass_amount,
                                  2
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "可用授信额度(元)" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.moneyFormat(
                                  _vm.creditDetail.available_aomunt,
                                  2
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "已用信额度(元)" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.moneyFormat(
                                  _vm.creditDetail.loan_amount,
                                  2
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("ics-product-inner", {
                attrs: { "product-detail": _vm.productDetail }
              }),
              _c(
                "ics-page-inner",
                { attrs: { title: "收款信息" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "收款人" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.bankFilesInfo.firmName
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm.method !== 0 ? _c("el-col") : _vm._e(),
                  _vm.method !== 0
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "收款银行" } }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(
                                    _vm.bankFilesInfo.bankName
                                  )
                                )
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "收款账户" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.bankFilesInfo.bankAccount
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "ics-page-inner",
                { attrs: { title: "批复额度" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "btnCode",
                            label: "审批结果",
                            rules: {
                              required: true,
                              message: "请选择审批结果",
                              trigger: "change"
                            }
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.btnCodeChange },
                              model: {
                                value: _vm.appForm.btnCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.appForm, "btnCode", $$v)
                                },
                                expression: "appForm.btnCode"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: "pass" } }, [
                                _vm._v(" 通过 ")
                              ]),
                              _c("el-radio", { attrs: { label: "back" } }, [
                                _vm._v(" 驳回 ")
                              ]),
                              _c("el-radio", { attrs: { label: "refusal" } }, [
                                _vm._v(" 拒绝 ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.appForm.btnCode === "pass"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "taskData.amount",
                                label: "批复金额(元)"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入批复金额"
                                },
                                model: {
                                  value: _vm.appForm.taskData.amount,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.appForm.taskData,
                                      "amount",
                                      $$v
                                    )
                                  },
                                  expression: "appForm.taskData.amount"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "taskData.remarks",
                            label: "审批意见",
                            rules: {
                              required: true,
                              message: "请输入审批意见",
                              trigger: "blur"
                            }
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入审批意见(限300字)",
                              maxlength: 300,
                              type: "textarea"
                            },
                            model: {
                              value: _vm.appForm.taskData.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm.taskData, "remarks", $$v)
                              },
                              expression: "appForm.taskData.remarks"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("ics-button-inner", {
                    attrs: {
                      loading: _vm.loading.submit,
                      "submit-title": "提交",
                      "cancel-title": "返回"
                    },
                    on: { submit: _vm.submitForms }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "ics-dialog-inner",
        {
          staticClass: "form-dialog",
          attrs: {
            width: "60%",
            visible: _vm.dialog.invoice.visible,
            title: _vm.dialog.invoice.title,
            "cancel-title": "返回",
            "show-submit": false
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog.invoice, "visible", $event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "partition-table" },
            [
              _c(
                "el-table",
                {
                  staticClass: "table-input",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.invoicesList,
                    border: "",
                    "max-height": "250"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "invoiceCode",
                      label: "发票编号",
                      align: "center",
                      formatter: _vm.utils.isEffective,
                      "show-overflow-tooltip": "",
                      "min-width": "100"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "invoiceKind",
                      label: "发票类型",
                      align: "center",
                      formatter: function(row, col, cell) {
                        return _vm.utils.statusFormat(cell, "invoiceKindType")
                      },
                      "show-overflow-tooltip": "",
                      "min-width": "100"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "buyerTaxName",
                      label: "采购方",
                      align: "center",
                      formatter: _vm.utils.isEffective,
                      "show-overflow-tooltip": "",
                      "min-width": "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sellerTaxName",
                      label: "销售方",
                      align: "center",
                      formatter: _vm.utils.isEffective,
                      "show-overflow-tooltip": "",
                      "min-width": "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "billingDate",
                      label: "开票时间",
                      align: "center",
                      formatter: _vm.utils.isEffective,
                      "show-overflow-tooltip": "",
                      "min-width": "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "totalAmount",
                      label: "金额(元)",
                      align: "center",
                      formatter: _vm.utils.tableMoneyFormat,
                      "show-overflow-tooltip": "",
                      "min-width": "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "totalTax",
                      label: "税额(元)",
                      align: "center",
                      formatter: _vm.utils.tableMoneyFormat,
                      "show-overflow-tooltip": "",
                      "min-width": "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "invoiceStatus",
                      label: "状态",
                      align: "center",
                      formatter: function(row, col, cell) {
                        return _vm.utils.statusFormat(cell, "invoiceStatus")
                      },
                      "show-overflow-tooltip": "",
                      "min-width": "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      fixed: "right",
                      align: "center",
                      "show-overflow-tooltip": "",
                      "min-width": "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "a",
                              {
                                staticClass: "text-btn",
                                attrs: { href: "javascript:" },
                                on: {
                                  click: function($event) {
                                    return _vm.utils.downloadP(
                                      "invoice",
                                      scope.row.invoiceUrl
                                    )
                                  }
                                }
                              },
                              [_vm._v("下载")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }